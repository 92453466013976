<template>
  <form action="#" @submit.prevent="handleSubmit">
    <div class="common-modal-headline">
      <h4 class="common-modal-title">
        {{ $t(`modal.set-client-discount`) }}
      </h4>
      <span
        class="material-symbols-rounded common-modal-close"
        @click="$emit('onClose')"
      >
        close
      </span>
    </div>

    <hr class="my-3" />

    <b-alert variant="danger" dismissible class="mt-3" :show="Boolean(error)">{{
      error
    }}</b-alert>

    <div class="form-group mb-3">
      <label class="required">{{ $t("form.discount") }}</label>
      <div class="input-group input-group-merge">
        <input
          v-model="form.discount"
          class="form-control"
          :placeholder="$t('form.discount-placeholder')"
          :class="{
            'is-invalid': submitted && $v.form.discount.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.discount.$error"
          class="invalid-feedback"
        >
          {{ $t("form.discount-valid") }}
        </div>
      </div>
    </div>

    <hr class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ $t("btn.set-discount") }}
      </button>
    </div>

    <!-- <pre>user: {{ user }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
  </form>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";

import { required, integer } from "vuelidate/lib/validators";

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error: "",

      form: {
        discount: "",
      },

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    formRules() {
      let rules = {};

      rules.discount = {
        required,
        integer,
      };

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["clientDiscount"]),
    initForm() {
      this.form = {
        discount: this.user.discount,
      };
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;

        try {
          await this.clientDiscount({
            id: this.user.id,
            discount: this.form.discount,
          });

          this.$emit("onSubmit");
        } catch (err) {
          this.error = this.$t("msg.error");

          if (err.response?.status && err.response?.data?.message) {
            this.error = `[${err.response.status}] ${err.response.data.message}`;
          }
        }

        this.trySubmit = false;
      }
    },
  },
  watch: {},
};
</script>
